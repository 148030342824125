import { useNavigate } from "react-router-dom"
import { useQueryClient } from "react-query"

import { logoutFn } from "parse/userFn"
import { toggleLoader } from "utils"
import { QUERY_KEYS } from 'constants/queryKey';

const useLogout = () => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const logout = async () => {
        toggleLoader(true)
        logoutFn()
            .then((res) => {
                queryClient.invalidateQueries([QUERY_KEYS.FETCH_CURRENT_USER])
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                toggleLoader(false)
            })
    }
    return logout;
}

export default useLogout;