const Parse = require("parse")

const getCurrentUserFn = () => {
    return new Promise(async (resolve, reject) => {
        let currentUser = await Parse.User.current()
        if (currentUser) {
            resolve(currentUser)
        } else {
            reject(null)
        }
    })
}

const logoutFn = () => {
    return new Promise(async (resolve, reject) => {
        let currentUser = await Parse.User.logOut()
        if (currentUser) {
            resolve(currentUser)
        } else {
            reject(null)
        }
    })
}

const loginFn = ({ user, password }) => {
    return new Promise(async (resolve, reject) => {
        console.log(user, password);

        const userLogin = new Parse.User()
        userLogin.set('username', user)
        userLogin.set('password', password)

        userLogin.logIn()
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                console.log(error.code, error.message)
                reject(error)
            })
    })
}

export { getCurrentUserFn, logoutFn, loginFn }