export const toggleLoader = (action) => {
    const loader = document.getElementById('OverlayLoaderID');
    if (action) {
        if (loader.classList.contains('d-none')) {
            loader.classList.remove('d-none');
        }
    } else {
        if (!loader.classList.contains('d-none')) {
            loader.classList.add('d-none');
        }
    }
}