import { useNavigate } from "react-router-dom"
import { useMutation, useQueryClient } from "react-query"

import { loginFn } from "parse/userFn"
// import { toggleLoader } from "utils"
import { QUERY_KEYS } from 'constants/queryKey';

const useLogin = () => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const doLogin = async (queryData) => {
        return await loginFn(queryData)
            .then((res) => {
                return res
            })
            .catch(error => {
                console.log(error)
                return null
            })
    }
    return useMutation(doLogin, {
        onSuccess: (data) => {
            console.log(data);

            navigate('/', { replace: true })
            queryClient.invalidateQueries([QUERY_KEYS.FETCH_CURRENT_USER])
        },
        onError: (error) => {

        }
    });
}

export default useLogin;