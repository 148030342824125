import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query'
import { QUERY_KEYS } from 'constants/queryKey';
import { toggleLoader } from 'utils';

import { getCurrentUserFn } from "parse/userFn"

const useFetchCurrentUser = () => {

    const navigate = useNavigate()

    const getCurrentUser = async () => {
        console.log('Inside get current user...');

        toggleLoader(true)
        return getCurrentUserFn()
            .then((res) => {
                console.log(res);

                return res
            })
            .catch(error => {
                console.log('Error getting user...');
                navigate('/login', { replace: true })
                return null;
            })
            .finally(() => {
                toggleLoader(false)
            })
    }

    return useQuery(QUERY_KEYS.FETCH_CURRENT_USER, getCurrentUser, { enabled: true })
}

export default useFetchCurrentUser;