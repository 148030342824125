import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { toast, ToastContainer } from 'react-toastify';

import useLogin from "hooks/useLogin";

const Parse = require("parse")

export interface PageLoginProps {
  className?: string;
}

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {

  const navigate = useNavigate()

  const { mutate: doLogin } = useLogin()

  const [user, setUser] = useState("asd@asd.com")
  const [password, setPassword] = useState("asd")
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getCurrentUser()
      .then((user) => {
        navigate('/', { replace: true })
      })
      .catch(error => {
        setLoading(false)
      })
  }, [])

  const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
      const user = Parse.User.current()
      if (user != null) {
        resolve(user)
      } else {
        reject('no user found')
      }
    })
  }

  const login = (e: any) => {
    e.preventDefault()
    setLoading(true)

    doLogin({ user, password })

    // const userLogin = new Parse.User()
    // userLogin.set('username', user)
    // userLogin.set('password', password)

    // userLogin.logIn()
    //   .then((res) => {
    //     setLoading(false)
    //     navigate('/', { replace: true })
    //   })
    //   .catch((error) => {
    //     setLoading(false)
    //     toast.warn(error.message)
    //     console.log(error.code, error.message)
    //   })
  }

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <form className="grid grid-cols-1 gap-6" onSubmit={login} action="">
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                onChange={(e) => setUser(e.target.value)}
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                value={user}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
                <Link to="/forgot-pass" className="text-sm">
                  Forgot password?
                </Link>
              </span>
              <Input type="password" className="mt-1" autoComplete="on" value={password} onChange={(e) => setPassword(e.target.value)} />
            </label>
            {
              loading ?
                <ButtonPrimary loading type="submit">Logging In...</ButtonPrimary>
                : <ButtonPrimary type="submit">Continue</ButtonPrimary>
            }
          </form>

          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link to="/signup">Create an account</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
