import MyRouter from "routers/index";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import { QueryClient, QueryClientProvider } from "react-query";

import OverlayLoader from "components/Loader/loaders";

const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <OverlayLoader />
      <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
        <ToastContainer
          autoClose={3000}
          pauseOnFocusLoss={false}
        />
        <MyRouter />
      </div>
    </QueryClientProvider>
  );
}

export default App;
