export const OverlayLoaderVisible = () => {
    return (
        <div className='loader-overlay'>
            <div className='overlay-spinner scale-class'>
                <div className='overlay-spinner-ball' />
                <p className='overlay-loading-text'>LOADING...</p>
            </div>
        </div>
    )
}

const OverlayLoader = () => {
    return (
        <div className='loader-overlay d-none' id="OverlayLoaderID">
            <div className='overlay-spinner scale-class'>
                <div className='overlay-spinner-ball' />
                <p className='overlay-loading-text'>LOADING</p>
            </div>
        </div>
    )
}

export default OverlayLoader;